<template>
  <v-simple-table background-color="transparent" class="mt-5">
    <thead>
      <tr>
        <td style="color: var(--v-primary-base); text-align: center">
          {{ $tc("concept", 1) }}
        </td>
        <td style="color: var(--v-primary-base); text-align: center">
          {{ $t("tattooers.type") }}
        </td>
        <td style="color: var(--v-primary-base); text-align: center">
          {{ $t("tattooers.price") }}
        </td>
        <td
          style="color: var(--v-primary-base); text-align: center"
          v-if="!$store.getters['auth/isStudio']"
        >
          {{ $tc("studio", 1) }}
        </td>
        <td></td>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(token_payment, i) in tattooer.token_payment.filter((x) =>
          studio_id ? x.studio_id === parseInt(studio_id) : true
        )"
        :key="i"
      >
        <td class="tokenTable pt-0">
          <v-text-field
            class="inputMargin"
            dense
            outlined
            hide-details
            v-model="token_payment.size"
            style="width: 100px; padding-top: 0 !important"
          ></v-text-field>
        </td>
        <td class="tokenTable">
          <v-select
            :items="comisionsType"
            outlined
            dense
            data-vv-validate-on="blur"
            v-validate="'required'"
            hide-details
            size="15"
            style="width: 80px; padding-top: 0 !important"
            v-model="token_payment.type"
          >
            <template v-slot:item="{ item }">
              <span style="font-size: 17px; margin: auto">{{ item.text }}</span>
            </template>
            <template v-slot:selection="{ item }">
              <span style="font-size: 17px; margin: auto">{{ item.text }}</span>
            </template>
          </v-select>
        </td>
        <td>
          <v-text-field
            :disabled="disabled"
            v-if="token_payment.type == 'percentage'"
            outlined
            dense
            type="number"
            v-on:keypress="isNumber($event)"
            hide-details
            v-model="token_payment.price"
            data-vv-validate-on="blur"
            :max="100"
            v-validate="'required|max_value:100'"
            class="inputMargin"
            style="width: 100px; padding-top: 0 !important"
          ></v-text-field>
          <number-input-with-separators
            :disabled="disabled"
            v-else
            :value="token_payment"
            valueKey="price"
            ref="money"
            rules="required|is_not:0€"
            class="inputMargin"
            dense
            outlined
            hide-details
            style="width: 100px; padding-top: 0 !important"
          ></number-input-with-separators>
        </td>
        <td class="tokenTable" v-if="!$store.getters['auth/isStudio']">
          <v-select
            class="inputMargin"
            dense
            outlined
            hide-details
            :items="studios"
            item-text="studio_name"
            item-value="id"
            v-model="token_payment.studio_id"
            :disabled="studios.length === 1"
          ></v-select>
        </td>
        <td class="tokenTable">
          <v-btn icon @click="del(i)" style="margin-left: -20px">
            <v-icon size="18">$delete</v-icon>
          </v-btn>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td colspan="4">
          <v-btn x-small fab outlined @click="add" style="margin-left: -10px">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </td>
      </tr>
    </tfoot>
  </v-simple-table>
</template>

<script>
export default {
  name: "TokenPayment",
  props: ["tattooer", "studio_id"],
  components: {
    NumberInputWithSeparators: () =>
      import("@/components/ui/NumberInputWithSeparators"),
  },
  data() {
    return {
      comisionsType: [
        { text: "%", value: "percentage", style: "font-size: 10px" },
        { text: "€", value: "money" },
      ],
    };
  },
  methods: {
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9.]+$/.test(char)) return true;
      else e.preventDefault();
    },
    add() {
      let item = {};
      if (this.studios.length === 1) item.studio_id = this.studios[0].id;
      this.tattooer.token_payment.push(item);
    },
    del(index) {
      this.$confirm("¿Seguro que quieres eliminar?", "", "").then(() =>
        this.tattooer.token_payment.splice(index, 1)
      );
    },
  },
  computed: {
    studios() {
      return this.tattooer.tattooer_studios
        ? this.tattooer.tattooer_studios
            .filter((x) => {
              if (this.studio_id)
                return x.studio.id == parseInt(this.studio_id);
              return true;
            })
            .map((x) => x.studio)
        : [];
    },
  },
};
</script>
<style lang="sass">
// td
 //height: 0px !important
</style>
